export interface ChatMessage {
  account: string
  text: string
  link?: string
  ts: number
  id: string
}

export interface AgoraMediaStream {
  streamID: number
  local: boolean
  account?: string
  stream?: any
  video?: boolean
  audio?: boolean
  playing?: boolean
}

export class AgoraStream {
  constructor(
    public readonly stream: any,
    public readonly streamID: number,
    public readonly local: boolean,
  ) {
  }
}
