import React, {useEffect} from 'react';
import VideoPlayer from '../components/video-player';
import Control from './whiteboard/control';
import {AgoraStream} from '../utils/types';
import useStream from '../hooks/use-streams';
import {useRoomState} from '../containers/root-container';
import {roomStore} from '../stores/room';
import {globalStore} from '../stores/global';
import {platform} from '../utils/platform';
import AgoraWebClient from '../utils/agora-rtc-client';
import {t} from '../i18n';
import './mediaboard.scss'

interface MediaBoardProps {
    handleClick?: (type: string) => void
    children?: any
}

const MediaBoard: React.FC<MediaBoardProps> = ({
                                                   handleClick,
                                                   children
                                               }) => {

    const roomState = useRoomState();
    const role = roomState.me.role;
    // const me = roomState.me;
    // const course = roomState.course;
    // const rtmState = roomState.rtm;

    // const ref = useRef<any>(false);
    const {sharedStream} = useStream();

    const shared = roomState.rtc.shared;

    useEffect(() => {
        if (!shared && platform === 'web') return;

        const rtcClient = roomStore.rtcClient;

        if (platform === 'web') {
            const webClient = rtcClient as AgoraWebClient;
            // WARN: IF YOU ENABLED APP CERTIFICATE, PLEASE SIGN YOUR TOKEN IN YOUR SERVER SIDE AND OBTAIN IT FROM YOUR OWN TRUSTED SERVER API
            const screenShareToken = '';
            webClient.startScreenShare(screenShareToken).then(() => {
                webClient.shareClient.on('onTokenPrivilegeWillExpire', (evt: any) => {
                    // WARN: IF YOU ENABLED APP CERTIFICATE, PLEASE SIGN YOUR TOKEN IN YOUR SERVER SIDE AND OBTAIN IT FROM YOUR OWN TRUSTED SERVER API
                    const newToken = '';
                    webClient.shareClient.renewToken(newToken);
                });
                webClient.shareClient.on('onTokenPrivilegeDidExpire', (evt: any) => {
                    // WARN: IF YOU ENABLED APP CERTIFICATE, PLEASE SIGN YOUR TOKEN IN YOUR SERVER SIDE AND OBTAIN IT FROM YOUR OWN TRUSTED SERVER API
                    const newToken = '';
                    webClient.shareClient.renewToken(newToken);
                });
                webClient.shareClient.on('stopScreenSharing', (evt: any) => {
                    console.log('stop screen share', evt);
                    webClient.stopScreenShare().then(() => {
                        globalStore.showToast({
                            message: t('toast.canceled_screen_share'),
                            type: 'notice'
                        });
                        roomStore.setScreenShare(false);
                    }).catch(console.warn).finally(() => {
                        console.log('[agora-web] stop share');
                    })
                })
                const localShareStream = webClient.shareClient._localStream
                const _stream = new AgoraStream(localShareStream, localShareStream.getId(), true);
                roomStore.addLocalSharedStream(_stream);
            }).catch((err: any) => {
                roomStore.setScreenShare(false);
                if (err.type === 'error' && err.msg === 'NotAllowedError') {
                    globalStore.showToast({
                        message: t('toast.canceled_screen_share'),
                        type: 'notice'
                    });
                }
                if (err.type === 'error' && err.msg === 'PERMISSION_DENIED') {
                    globalStore.showToast({
                        message: t('toast.screen_sharing_failed', {reason: err.msg}),
                        type: 'notice'
                    });
                }
                console.warn(err);
            }).finally(() => {
                console.log('[agora-web] start share');
            })
            return () => {
                console.log("before shared change", shared);
                shared && webClient.stopScreenShare().then(() => {
                    roomStore.setScreenShare(false);
                }).catch(console.warn).finally(() => {
                    console.log('[agora-web] stop share');
                })
            }
        }
    }, [shared]);

    const handlePageTool: any = (evt: any, type: string) => {
        if (type === 'screen_sharing') {
            roomStore.setScreenShare(true);
        }

        if (type === 'quit_screen_sharing') {
            roomStore.setScreenShare(false);
        }

        if (handleClick) {
            handleClick(type);
        }
    }


    // const [selector, updateSelector] = useState<string>('');

    // const lock = useRef<boolean>(false);

    // const globalState = useGlobalState();

    return (
        <div className={`media-board`}>
            {sharedStream ?
                <VideoPlayer
                    id={`${sharedStream.streamID}`}
                    domId={`shared-${sharedStream.streamID}`}
                    className={'screen-sharing'}
                    streamID={sharedStream.streamID}
                    stream={sharedStream.stream}
                    video={true}
                    audio={true}
                    local={sharedStream.local}
                />
                :
                <></>
            }

            {sharedStream && sharedStream.local ?
                <Control
                    role={role}
                    sharing={Boolean(sharedStream)}
                    onClick={handlePageTool}/> : null
            }
        </div>
    )
}

export default React.memo(MediaBoard);
