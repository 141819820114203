import React from 'react';
import Icon from '../icon';
interface ControlItemProps {
  name: string
  onClick: (evt: any, name: string) => void
  active: boolean
  text?: string
}

const ControlItem = (props: ControlItemProps) => {
  const onClick = (evt: any) => {
    props.onClick(evt, props.name);
  }
  return (
    props.text ?
      <div className={`control-btn control-${props.name}`} onClick={onClick}>
        <div className={`btn-icon ${props.name} ${props.active ? 'active' : ''}`}
          data-name={props.name} />
        <div className="control-text">{props.text}</div>
      </div>
      :
      <Icon
        data={props.name}
        onClick={onClick}
        className={`items ${props.name} ${props.active ? 'active' : ''}`}
      />
  )
}

interface ControlProps {
  sharing: boolean
  isHost?: boolean
  role: string
  onClick: (evt: any, type: string) => void
}

export default function Control({
  sharing,
  isHost,
  onClick,
  role,
}: ControlProps) {
  return (
    <div className="controls-container">
        {role === 'teacher' ?
          <>
          <div className="controls">
            <ControlItem
              name={sharing ? 'quit_screen_sharing' : 'screen_sharing'}
              onClick={onClick}
              active={false}
              text={sharing ? 'stop sharing' : ''}
            />
          </div>
          </> : null }
    </div>
  )
}
